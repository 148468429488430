import { KcmContent } from "@/types";

/**
 * Latest 2023 research revealed 238 wpm: https://osf.io/preprints/psyarxiv/xynwg
 *
 * - Takes in an input of strings, and uses the dom parser to remove any html markup.
 * - Trims the parsed text and splits at each whitespace to calculate the numbers of words.
 * - Divides by wpm and returns a conditional string based on that value being less than or greater than 1.
 *
 * @param content <string> the content string
 * @returns <string> the formatted string to display
 */
export function estimateReadingTime(content: string): string {
  const wpm = 238; // average wpm according to 2023 research
  const parser = new DOMParser();
  const text = parser.parseFromString(content, "text/html").body.innerText;

  const minutes = text.trim().split(/\s+/).length / wpm;

  if (minutes < 1) {
    return "Less than one minute";
  } else {
    return `${Math.ceil(minutes)} min read`;
  }
}

export function isInfographic(content: KcmContent): boolean {
  return isCategory("infographics", content) || isCategory("holidays", content);
}

export function isHoliday(content: KcmContent): boolean {
  return isCategory("holidays", content);
}

export function isCategory(check: string, content: KcmContent): boolean {
  if (!content.categories) {
    return false;
  }
  return (
    content.categories.filter((category) => category.slug === check).length > 0
  );
}

export function getInfographic(content: KcmContent): string {
  if (!content.contents) {
    return "";
  }
  const match = content.contents.match(/src\s*=\s*"(.+?)"/);
  if (!match) {
    return "";
  }
  return match[1];
}

export function contentTypeText(content: KcmContent): string | undefined {
  const type = isInfographic(content) ? "infographic" : content.content_type;
  return (
    {
      blog: "BLOG",
      infographic: "INFOGRAPHIC",
      script: "REALTALK SCRIPT",
      videos: "VIDEO",
      "social-graphics": "GRAPHIC",
      mmr: "MONTHLY MARKET REPORT",
      "deep-dive": "DEEP DIVE",
      "webinar-replay": "WEBINAR REPLAY",
      "training-video": "TRAINING VIDEO",
      "best-practice": "BEST PRACTICE",
    }[type] ?? type.replaceAll("-", " ")
  );
}

export function embedScriptForVideo(url: string): string {
  if (url.toLowerCase().includes("vimeo")) {
    return `<script src="https://player.vimeo.com/api/player.js">`;
  }

  return "";
}

const pluralToSingularMap = {
  alerts: "alert",
  "social-graphics": "social-graphic",
  videos: "video",
  "strategy-calls": "strategy-call",
  "lunch-and-learns": "lunch-and-learn",
} as const;

type PluralContentType = keyof typeof pluralToSingularMap;
type SingularContentType = (typeof pluralToSingularMap)[PluralContentType];

export function useContentTypeUtils(): {
  convertContentType: (type: string, toSingular: boolean) => string;
} {
  function convertContentType(type: string, toSingular = true): string {
    if (toSingular) {
      return pluralToSingularMap[type as PluralContentType] || type;
    } else {
      const singularToPlural = Object.fromEntries(
        Object.entries(pluralToSingularMap).map(([plural, singular]) => [
          singular,
          plural,
        ])
      ) as { [K in SingularContentType]: PluralContentType };
      return singularToPlural[type as SingularContentType] || type;
    }
  }

  return {
    convertContentType,
  };
}
