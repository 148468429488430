import {
  insensitiveAssign,
  saveToLocalStorage,
} from "@/composables/helperUtils";
import {
  ApplePaySubscriptionData,
  BillingStore,
  KcmBilling,
  BillingSource,
  BillingGenericNoData,
  PlanData,
} from "@/types";
import { applePayService } from "@/services/billingservice/applepay.service";
import {
  recurlyService,
  nullDate,
} from "@/services/billingservice/recurly.service";

import { Commit } from "vuex";
import {
  RecurlySubscriptionData,
  RecurlyAccountData,
  RecurlyPlanData,
} from "@/types/recurly.types";
import { guestService } from "@/services/billingservice/guest.service";

const defaultBilling = (): BillingStore => {
  return {
    source: "guest",
    subscription: { error: "" },
    account: { error: "" },
    plans: [],
    last_sync: 0,
    redirect: "/upgrade",
  };
};

const getDefaultState = (): BillingStore => {
  const billingFromStorage = localStorage.getItem("billing");
  if (billingFromStorage) {
    const storage = insensitiveAssign(
      defaultBilling(),
      JSON.parse(billingFromStorage)
    );
    if (storage) {
      return storage;
    }
  }
  return defaultBilling();
};

type StoreGetters = {
  billingInFreeTrial: boolean;
  billingSubscription: RecurlySubscriptionData;
  billingAccount: RecurlyAccountData;
};

export const state: BillingStore = getDefaultState();

export const getters = {
  billingStoreService(state: BillingStore): KcmBilling {
    if (state.source === "apple") {
      return applePayService;
    } else if (state.source === "guest") {
      return guestService;
    }
    return recurlyService;
  },
  billingSource(state: BillingStore): string {
    return state.source;
  },
  billingSubscription(
    state: BillingStore
  ): RecurlySubscriptionData | ApplePaySubscriptionData | unknown {
    return state.subscription ?? undefined;
  },
  billingAccount(
    state: BillingStore
  ): RecurlyAccountData | BillingGenericNoData {
    return state.account;
  },
  billingPlans(state: BillingStore): unknown {
    return state.plans;
  },
  billingHasPendingCancel(state: BillingStore): boolean {
    return getters.billingState(state) === "canceled";
  },
  billingHasPendingChange(state: BillingStore): boolean {
    if ("pending_change" in state.subscription) {
      return (
        (state.subscription.pending_change?.created_at ?? nullDate) !== nullDate
      );
    }
    return false;
  },
  billingPauseDate(state: BillingStore): string {
    if (
      "paused_at" in state.subscription &&
      state.subscription.paused_at !== nullDate
    ) {
      return state.subscription.paused_at;
    }
    return "";
  },
  billingState(state: BillingStore): string {
    if ("state" in state.subscription) {
      return state.subscription.state;
    }
    return "";
  },
  billingHasPendingPause(state: BillingStore): boolean {
    if (
      getters.billingPauseDate(state) &&
      getters.billingState(state) !== "paused"
    ) {
      return true;
    }
    return false;
  },
  billingIsPaused(state: BillingStore): boolean {
    if (
      getters.billingPauseDate(state) &&
      getters.billingState(state) === "paused"
    ) {
      return true;
    }
    return false;
  },
  billingInFreeTrial(): boolean {
    if (!("trial_ends_at" in state.subscription)) {
      return false;
    }
    return new Date() < new Date(state.subscription.trial_ends_at);
  },
  lastSync(state: BillingStore): number {
    return state.last_sync;
  },
  isLegacy(
    state: BillingStore,
    getters: {
      billingPlans: unknown;
      billingSubscription: RecurlySubscriptionData;
    }
  ): boolean {
    //potentially need to add something here that checks if billingSubscription is
    //apple or unknown and return "true" if so

    // filter the list of available plans down to the one the user currently has
    const filteredPlans = (getters.billingPlans as RecurlyPlanData[]).filter(
      (plan) => {
        return plan.code === getters.billingSubscription.plan.code;
      }
    );

    // if that filtered list has 0 items, the user is on a legacy plan
    return filteredPlans.length === 0;
  },
  isMonthly(
    state: BillingStore,
    getters: { billingSubscription: RecurlySubscriptionData }
  ): boolean {
    return getters.billingSubscription.plan.code.split("-")[1] === "monthly";
  },
  canConvertToAnnual(
    state: BillingStore,
    getters: {
      billingSubscription: RecurlySubscriptionData;
      billingAccount: RecurlyAccountData;
      isMonthly: boolean;
      isLegacy: boolean;
    }
  ): boolean {
    const teamAddons = getters.billingSubscription.add_ons?.filter((addon) => {
      return addon.add_on.code === "team-member";
    });

    return (
      !getters.billingAccount.has_past_due_invoice &&
      getters.isMonthly &&
      !getters.isLegacy &&
      getters.billingAccount.has_live_subscription &&
      (!teamAddons || teamAddons?.length === 0)
    );
  },
  freeTrialTier(state: BillingStore, getters: StoreGetters): string {
    if (getters.billingInFreeTrial) {
      const planCode = getters.billingSubscription.plan.code;
      return planCode.split("-")[0];
    }
    return "No Free Trial";
  },
};

export const actions = {
  setBillingSource(
    { commit }: { commit: Commit },
    source: BillingSource
  ): void {
    commit("SET_BILLING_DATA", defaultBilling());

    commit("SET_BILLING_SOURCE", source);
    commit("SET_LAST_SYNC");
  },
  setSubscriptionData(
    { commit }: { commit: Commit },
    subscription:
      | RecurlySubscriptionData
      | ApplePaySubscriptionData
      | BillingGenericNoData
  ): void {
    commit("SET_SUBSCRIPTION_DATA", subscription);
  },
  setAccountData(
    { commit }: { commit: Commit },
    account: RecurlyAccountData | BillingGenericNoData
  ): void {
    commit("SET_ACCOUNT_DATA", account);
  },
  setPlansData({ commit }: { commit: Commit }, plans: unknown): void {
    commit("SET_PLANS_DATA", plans);
  },
  logout({ commit }: { commit: Commit }): void {
    commit("SET_BILLING_DATA", defaultBilling());
    commit("SET_LAST_SYNC");
  },
};

export const mutations = {
  SET_BILLING_SOURCE(state: BillingStore, newValue: BillingSource): void {
    state.source = newValue;
    saveToLocalStorage("billing", state);
  },
  SET_SUBSCRIPTION_DATA(
    state: BillingStore,
    newValue:
      | RecurlySubscriptionData
      | ApplePaySubscriptionData
      | BillingGenericNoData
  ): void {
    state.subscription = newValue;
    saveToLocalStorage("billing", state);
  },
  SET_ACCOUNT_DATA(
    state: BillingStore,
    newValue: RecurlyAccountData | BillingGenericNoData
  ): void {
    state.account = newValue;
    saveToLocalStorage("billing", state);
  },
  SET_PLANS_DATA(state: BillingStore, newValue: PlanData[]): void {
    state.plans = newValue;
    saveToLocalStorage("billing", state);
  },
  SET_BILLING_DATA(state: BillingStore, newValue: BillingStore): void {
    Object.assign(state, newValue);
    saveToLocalStorage("billing", state);
  },
  SET_LAST_SYNC(state: BillingStore): void {
    state.last_sync = parseInt(Date.now().toString());
    saveToLocalStorage("billing", state);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
