import TrackingService from "@/services/trackingservice/tracking.service";
import Vue, { VNode } from "vue";
import BootstrapVue from "bootstrap-vue";
// import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap-vue/dist/bootstrap-vue.css";
import Vuelidate from "vuelidate";
import VueMask from "v-mask";
import VueRouter from "vue-router";
import vco from "v-click-outside";
import router from "./router/index";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import "@/design/industry/init.scss";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import store from "@/state/store";

import App from "./App.vue";

import i18n from "./i18n";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import { VueMasonryPlugin } from "vue-masonry";
import "vue-datetime/dist/vue-datetime.css";
import VueMeta from "vue-meta";

import vueDebounce from "vue-debounce";
import Multiselect from "vue-multiselect";

import GoogleAnalytics from "./services/trackingservice/GoogleAnalytics";
import UserpilotTracker from "./services/trackingservice/UserPilot";
import AmplitudeTracker from "./services/trackingservice/Amplitude";
import ChurnZeroTracker from "./services/trackingservice/ChurnZero";

import { Options } from "@sentry/vue/types/types";
import Hubspot from "./services/trackingservice/Hubspot";

library.add(fab);
library.add(fas);
library.add(far);
Vue.component("FontAwesomeIcon", FontAwesomeIcon);

const sentryOptions: Partial<Options> = {
  Vue,
  dsn: "https://ffbc1b20565a43b2804b411e2d9d48de@o113022.ingest.sentry.io/4504749571178496",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: [],
    }),
  ],
  environment: process.env.NODE_ENV,
  release: "mykcm-rebuild-ts@" + process.env.VUE_APP_VERSION,
  // Adjusting this to half the current rate for now. This will randomly choose half of the
  // potential performance unit transactions to actually send and store in sentry. Doing half
  // since we are getting cut off around the halfway point currently. We can use a custom function
  // though if we want to actually target these transactions more aggressively. Check out this link for
  // further reference: https://docs.sentry.io/platforms/javascript/configuration/sampling/
  tracesSampleRate: 0.5,
  ignoreErrors: [
    "PrivacyError",
    // Random plugins/extensions
    "top.GLOBALS",
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    "originalCreateNotification",
    "canvas.contentDocument",
    "MyApp_RemoveAllHighlights",
    "http://tt.epicplay.com",
    "Can't find variable: ZiteReader",
    "jigsaw is not defined",
    "ComboSearch is not defined",
    "http://loading.retry.widdit.com/",
    "atomicFindClose",
    // Facebook borked
    "fb_xd_fragment",
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    "bmi_SafeAddOnload",
    "EBCallBackMessageReceived",
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    "conduitPage",
    // Generic error code from errors outside the security sandbox
    // You can delete this if using raven.js > 1.0, which ignores these automatically.
    "Script error.",
    // Avast extension error
    "_avast_submit",
    // https://github.com/getsentry/sentry-javascript/issues/3440
    "Non-Error promise rejection captured",
    "ResizeObserver loop limit exceeded",
    "ResizeObserver loop completed with undelivered notifications",
    "Network Error",
  ],
  denyUrls: [
    // Google Adsense
    /pagead\/js/i,
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    /js\.convertflow\.co/i,
    /ipa\.elev\.io/i,
  ],
};

const environment = process.env.NODE_ENV + "";
if (["production", "staging"].includes(environment)) Sentry.init(sentryOptions);

Vue.use(VueRouter);
Vue.use(vco);
// const VueScrollTo = require("vue-scrollto");
// Vue.use(VueScrollTo);
Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(Vuelidate);
Vue.use(VueMask);
Vue.use(vueDebounce);
// Vue.use(require("vue-chartist"));
Vue.use(VueMasonryPlugin);

Vue.use(Toast);

Vue.use(VueMeta, {
  // The component option name that vue-meta looks for meta info on.
  keyName: "page",
});

Vue.component("MultiSelect", Multiselect);

declare module "vue/types/options" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ComponentOptions<V extends Vue> {
    page?: {
      title?: string;
      meta?: { name: string; content: string }[];
      titleTemplate?(title: string): string;
    };
  }
}

/**
 * Register our event trackers
 */
if (process.env.VUE_APP_GA4_ID) {
  TrackingService.registerTracker(GoogleAnalytics);
}
// Register trackers
if (
  process.env.VUE_APP_CHURNZERO_API_KEY &&
  process.env.VUE_APP_CHURNZERO_DOMAIN
) {
  ChurnZeroTracker.init();
  TrackingService.registerTracker(ChurnZeroTracker);
}
if (process.env.VUE_APP_USERPILOT_TOKEN) {
  UserpilotTracker.init();
  TrackingService.registerTracker(UserpilotTracker);
}
if (process.env.VUE_APP_AMPLITUDE_TOKEN) {
  AmplitudeTracker.init();
  TrackingService.registerTracker(AmplitudeTracker);
}

TrackingService.registerTracker(Hubspot);

new Vue({
  name: "MainEntry",
  router,
  store,
  i18n,
  render: (h): void | VNode | null => h(App),
}).$mount("#app");
