import store from "@/state/store";
import { AuthUser } from "@/types";
import * as amplitude from "@amplitude/analytics-browser";
import { autocapturePlugin } from "@amplitude/plugin-autocapture-browser";

const AmplitudeTracker = {
  name(): string {
    return "amplitude";
  },

  init(): void {
    if (!process.env.VUE_APP_AMPLITUDE_TOKEN) {
      return;
    }
    amplitude.init(process.env.VUE_APP_AMPLITUDE_TOKEN, {
      defaultTracking: {
        pageViews: false,
      },
    });
    amplitude.add(autocapturePlugin());
  },

  async identify(user: Partial<AuthUser>): Promise<void> {
    if (!user.id) {
      amplitude.reset();
      return;
    }

    const userPlanFeature = store.getters["auth/getPlanSlug"];

    const userProperties = {
      name: `${user.first_name} ${user.last_name}`,
      member_id: user.id,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      plan_tier: userPlanFeature,
    };

    await this.updateUserAttributes(userProperties);
  },

  async updateUserAttributes(data: Record<string, unknown>): Promise<void> {
    const userId = store.getters["auth/authUserId"];

    if (window.enableKcmDebug) {
      console.log(`Amplitude user property update: ${userId}: `, data);
    }

    const identifyEvent = new amplitude.Identify();
    identifyEvent.set("user_id", userId);
    for (const key in data) {
      identifyEvent.set(key, data[key] as string);
    }
    amplitude.identify(identifyEvent);
  },

  event(name: string, data?: Record<string, string>): void {
    if (window.enableKcmDebug) {
      console.log(`Amplitude event: ${name}: `, data);
    }

    amplitude.track(name.toLowerCase(), data);
  },

  pageView(): void {
    this.event("Page Viewed");
  },
};

export default AmplitudeTracker;
