import { billingService } from "../billingservice/billing.service";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import store from "@/state/store";
import { profileService } from "@/services/internal-api/profile.service";
import {
  AuthApiResp,
  AuthUser,
  AuthUserPost,
  GenericApiError,
  SocialLoginPost,
} from "@/types";
import { DateTimeFormatOptions } from "vue-i18n";
import { helpers } from "../helpers";
import router from "@/router";
import { thirdPartyService } from "./thirdparty.service";
import cache from "@/services/cache/cache.service";
import TrackingService from "../trackingservice/tracking.service";

const HOST = process.env.VUE_APP_AUTH_API_ROOT
  ? process.env.VUE_APP_AUTH_API_ROOT.replace(/\/$/, "")
  : "";

const industry = process.env.VUE_APP_INDUSTRY_ID ?? "1";

const featureCheck = process.env.VUE_APP_FEATURE_CHECK ?? "";

const ERRORS = {
  DELETE_REQUEST_ERR: "The delete request could not be completed",
  DELETE_USER_ERR: "Your account could not be deleted at this time",
};

// const SERVICES = {
//     facebook: 1,
//     twitter: 2,
//     linkedin: 3,
//     google: 4,
//     apple: 5,
//     "apple-pay": 6
// };
const HEADERS = (overrideToken?: string): AxiosRequestConfig => {
  if (overrideToken) {
    return {
      headers: {
        authorization: `Bearer ${overrideToken}`,
      },
    };
  }

  const userToken = store.getters["auth/authToken"];
  if (userToken !== undefined && userToken !== null) {
    return {
      headers: {
        authorization: `Bearer ${userToken}`,
      },
    };
  }
  return {
    auth: {
      username: process.env.VUE_APP_AUTH_API_USER || "",
      password: process.env.VUE_APP_AUTH_API_KEY || "",
    },
  };
};

export const userService = {
  get,
  post,
  deleteAxios,
  login,
  loginToken,
  loginSocial,
  loginImpersonate,
  confirmLogin,
  getAccount,
  getUser,
  createUser,
  updateAccount,
  updateAccountApi,
  validateEmailChange,
  sendPasswordResetEmail,
  impersonateTeamMember,
  validatePasswordReset,
  formatTimestamp,
  updateServiceData,
  removeServiceData,
  syncUserData,
  requestAccountDeletion,
  deleteUser,
  startTrialHandler,
  ERRORS,
};

function get(
  resource: string,
  overrideToken?: string
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
): Promise<AxiosResponse<any, any>> {
  return axios.get(HOST + resource, HEADERS(overrideToken));
}

function post(
  resource: string,
  data?: unknown,
  overrideToken?: string
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
): Promise<AxiosResponse<any, any>> {
  return axios.post(HOST + resource, data, HEADERS(overrideToken));
}

// function put(resource, data) {
//     return axios.put(HOST + resource, data, HEADERS());
// }

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
function deleteAxios(resource: string, overrideToken?: string): any {
  return axios.delete(HOST + resource, HEADERS(overrideToken));
}

async function login(
  username: string,
  password: string
): Promise<{
  success: boolean;
  error?: string;
}> {
  const endpoint = "/authenticate";
  const requestOptions = {
    username: username,
    password: password,
    industry_id: parseInt(industry),
  };

  return post(endpoint, requestOptions)
    .then(async (response: AuthApiResp) => {
      const resp = await confirmLogin(response, "password");
      if (!resp.success) {
        return { success: false, error: resp.error };
      }
      return { success: true };
    })
    .catch(() => {
      return { success: false, error: "Invalid Login" };
    });
}

async function loginSocial(
  password: string,
  type: string,
  username?: string
): Promise<{
  success: boolean;
  error?: string;
  multiple?: boolean;
  users?: AuthUser[];
}> {
  const endpoint = "/authenticate/" + type;
  const requestOptions: SocialLoginPost = {
    password: password,
    authMethod: "social",
    industry_id: parseInt(industry),
  };
  if (username) {
    requestOptions.username = username;
  }

  return post(endpoint, requestOptions)
    .then(async (response: AuthApiResp) => {
      const resp = await confirmLogin(response, type);
      if (!resp.success) {
        return {
          success: false,
          error: resp.error,
          multiple: resp.multiple,
          users: resp.users,
        };
      }
      return { success: true };
    })
    .catch(() => {
      return { success: false, error: "Invalid Login" };
    });
}

async function loginToken(token: string): Promise<{
  success: boolean;
  error?: string;
}> {
  const endpoint = "/authenticate/token";
  const requestOptions = {
    password: token,
    industry_id: parseInt(industry),
  };

  return post(endpoint, requestOptions)
    .then(async (response: AuthApiResp) => {
      const resp = await confirmLogin(response, "password");
      if (!resp.success) {
        return { success: false, error: resp.error };
      }
      return { success: true };
    })
    .catch(() => {
      return { success: false, error: "Invalid Login" };
    });
}

async function impersonateTeamMember(
  ownerId: number,
  id: number
): Promise<void> {
  const resource = `/users/${ownerId}/teams/impersonate/${id}`;

  const data = {
    industry_id: parseInt(industry),
  };

  try {
    const response: AuthApiResp = await post(resource, data);

    await store.dispatch("auth/setTeamOwner");
    const resp = await confirmLogin(response, "team_owner_impersonation");
    if (!resp.success) {
      throw resp;
    }

    cache.clear("content");
  } catch (err) {
    await store.dispatch("auth/swapTeamOwnerToActive");
  }
}

async function loginImpersonate(userId: number): Promise<{
  success: boolean;
  error?: string;
}> {
  const endpoint = "/authenticate";
  const requestOptions = {
    id: userId,
    industry_id: parseInt(industry),
  };

  // override the token with the auth token, if necessary
  let overrideToken = null;
  const authAdmin = store.getters["auth/authAdmin"];
  if (authAdmin) {
    overrideToken = authAdmin.token;
  }

  return post(endpoint, requestOptions, overrideToken)
    .then(async (response: AuthApiResp) => {
      // set the auth admin account if it hasn't already been set
      if (!authAdmin && response.data.token) {
        await store.dispatch("auth/setAuthAdmin");
      }
      const resp = await confirmLogin(response, "password");
      if (!resp.success) {
        await store.dispatch("auth/swapAuthToActive");
        return { success: false, error: resp.error };
      }

      cache.clear("content");
      return { success: true };
    })
    .catch(async () => {
      await store.dispatch("auth/swapAuthToActive");
      return { success: false, error: "Invalid Login" };
    });
}

async function confirmLogin(
  response: AuthApiResp,
  type: string
): Promise<{
  success: boolean;
  multiple?: boolean;
  users?: AuthUser[];
  error?: string;
}> {
  if (response.data.multiple) {
    return {
      success: false,
      multiple: true,
      users: response.data.users,
    };
  }

  if (!response.data.token || !response.data.user.id) {
    return { success: false, error: "Invalid login" };
  } else {
    //clear other stores
    await store.dispatch("profile/logout");
    await store.dispatch("billing/logout");

    await store.dispatch("auth/login", response.data);
    if (
      featureCheck &&
      !store.getters["auth/hasFeature"](featureCheck) &&
      !store.getters["auth/authIsCrew"]
    ) {
      await store.dispatch("auth/logout");
      return { success: false, error: "Invalid access" };
    }

    await store.dispatch("settings/loadUserSettings");
    await store.dispatch("autoposter/setAutoposterJobs", null);
    await syncUserData(response.data.user.id, {
      auth: false,
      profile: true,
      billing: true,
      crm: false,
    });
    //track the event before dispatching login so that funnels in the
    //tracking services show the correct order with the login event happening
    //prior to the dashboard pageview
    TrackingService.trackEvent("login", { method: type });
    return { success: true };
  }
}

async function syncUserData(
  userId: string,
  options: {
    auth: boolean;
    profile?: boolean;
    billing?: boolean;
    crm?: boolean;
  }
): Promise<string> {
  const errors: string[] = [];

  if (options.auth) {
    const resp = await getAccount(userId);
    if (resp) errors.push(resp);
  }

  if (options.profile) {
    //returns false if no errors - returns something else if errors.
    const resp = await profileService.loadProfile(userId);

    if (resp !== false) {
      errors.push("Error loading member profile");
    }
  }

  if (options.billing) {
    await billingService.getPaymentService();
    await billingService.getSubscriptionData(false);
  }

  if (options.crm) {
    await thirdPartyService.getMembersHubspotLists(
      store.getters["auth/authEmail"]
    );
  }

  return errors.join(";");
}

async function getAccount(userId: string): Promise<string | false> {
  const response = await getUser(userId);
  if (typeof response !== "string") {
    await store.dispatch("auth/setCurrentUser", response);
    return false;
  }
  return response;
}

async function getUser(userId: string): Promise<string | AuthUser> {
  return get("/users/" + userId + "?expand=all&industry=" + industry)
    .then((response: AuthApiResp) => {
      if ("data" in response && "user" in response.data) {
        return response.data.user;
      } else {
        return "Error getting account data";
      }
    })
    .catch((err) => {
      if (err.response.data.message === "User not found") {
        router.replace("/logout").catch(() => {
          return;
        });
      }
      return "Error getting account data";
    });
}

async function createUser(payload: AuthUserPost): Promise<string | AuthUser> {
  if (!payload.industry_id) {
    payload.industry_id = parseInt(industry);
  }
  return post("/users/", payload)
    .then((response: AuthApiResp) => {
      if ("data" in response && "user" in response.data) {
        return response.data.user;
      } else {
        return "Error updating account";
      }
    })
    .catch(() => {
      return "Error creating account";
    });
}

async function updateAccount(
  userId: string,
  data: AuthUserPost
): Promise<string | boolean> {
  if (!data.industry_id) {
    data.industry_id = parseInt(industry);
  }
  if (data.email) {
    if (data.email !== store.getters["auth/authEmail"]) {
      const error = await sendValidationEmail(userId, data.email).then(
        (error) => {
          return error;
        }
      );
      if (error) {
        return "Error sending validation email";
      }
      delete data.email;
    }
  }

  return updateAccountApi(userId, data).then((error) => {
    return error;
  });
}

async function updateAccountApi(
  userId: string,
  data: AuthUserPost
): Promise<string | boolean> {
  if (!data.industry_id) {
    data.industry_id = parseInt(industry);
  }
  return post("/users/" + userId, data)
    .then(async (response: AuthApiResp) => {
      if ("data" in response && "user" in response.data) {
        await store.dispatch("auth/setCurrentUser", response.data.user);

        return false;
      } else {
        return "Error updating account";
      }
    })
    .catch(() => {
      return "Error updating account";
    });
}

async function updateServiceData(
  userId: string,
  service: string,
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  data: any
): Promise<boolean | string> {
  return post(
    "/users/" + userId + "/services/slug-" + service + "?industry=" + industry,
    data
  )
    .then(async (response: AuthApiResp) => {
      if ("data" in response && "user" in response.data) {
        getAccount(userId);

        return false;
      } else {
        return "Error updating service";
      }
    })
    .catch(() => {
      return "Error updating service";
    });
}

async function removeServiceData(
  userId: string,
  service: string
): Promise<boolean | string> {
  return deleteAxios(
    "/users/" + userId + "/services/slug-" + service + "?industry=" + industry
  )
    .then(async (response: AuthApiResp) => {
      if ("data" in response && "user" in response.data) {
        getAccount(userId);

        return false;
      } else {
        return "Error updating service";
      }
    })
    .catch(() => {
      return "Error updating service";
    });
}

async function sendValidationEmail(
  userId: string,
  emailAddress: string,
  redirect?: string
): Promise<string> {
  const data = {
    email: emailAddress,
    redirect:
      redirect ?? process.env.VUE_APP_URL + "account/?action=validate_email",
    industry: parseInt(industry),
  };

  return post("/users/" + userId + "/request_email_change/", data)
    .then(async () => {
      return "";
    })
    .catch((err) => {
      return err;
    });
}

async function validateEmailChange(
  userId: string,
  emailAddress: string,
  validationCode: string
): Promise<string> {
  return post(
    "/users/" +
      userId +
      "/confirm_email_change/?email=" +
      encodeURIComponent(emailAddress) +
      "&h=" +
      encodeURIComponent(validationCode)
  )
    .then(async (response: AuthApiResp | GenericApiError) => {
      if ("data" in response && "user" in response.data) {
        await store.dispatch("auth/setCurrentUser", response.data.user);

        return "";
      } else {
        if ("error" in response) {
          return response.error as string;
        }
      }

      return "Something went wrong when attempting to confirm validation.";
    })
    .catch((err) => {
      return err;
    });
}

async function sendPasswordResetEmail(emailAddress: string): Promise<string> {
  const data = {
    email: emailAddress,
    redirect: process.env.VUE_APP_URL + "reset-password/",
    industry: parseInt(industry),
  };

  return post("/users/request_password_change/", data)
    .then(async () => {
      return "";
    })
    .catch((err) => {
      return err;
    });
}

async function validatePasswordReset(
  token: string,
  validationCode: string
): Promise<boolean> {
  return post(
    "/users/confirm_password_change/?token=" +
      encodeURIComponent(token) +
      "&h=" +
      encodeURIComponent(validationCode) +
      "&industry=" +
      process.env.VUE_APP_INDUSTRY_ID
  )
    .then(async (response: AuthApiResp) => {
      const resp = await confirmLogin(response, "password");
      if (!resp.success) {
        helpers.customSentryError(
          "Validate Password Reset Response Error",
          "Validate Password Reset response error.",
          {
            token: token,
            hash: validationCode,
            response: resp,
          }
        );
        return false;
      }
      store.dispatch("auth/setForceRedirect", "/reset-password");
      return true;
    })
    .catch((err) => {
      helpers.customSentryError(
        "Validate Password Reset Network Error",
        "Validate Password Reset network error.",
        {
          token: token,
          hash: validationCode,
          response: err,
        }
      );
      return false;
    });
}

// async function validatePasswordReset(
//   emailAddress: string,
//   validationCode: string
// ): Promise<boolean> {
//   return post(
//     "/users/confirm_password_change/?email=" +
//       encodeURIComponent(emailAddress) +
//       "&h=" +
//       encodeURIComponent(validationCode) +
//       "&industry=" +
//       process.env.VUE_APP_INDUSTRY_ID
//   )
//     .then(async (response: AuthApiResp) => {
//       const resp = await confirmLogin(response, "password");
//       if (!resp.success) {
//         helpers.customSentryError(
//           "Validate Password Reset Response Error",
//           "Validate Password Reset response error.",
//           {
//             email: emailAddress,
//             hash: validationCode,
//             response: resp,
//           }
//         );
//         return false;
//       }
//       store.dispatch("auth/setForceRedirect", "/reset-password");
//       return true;
//     })
//     .catch((err) => {
//       helpers.customSentryError(
//         "Validate Password Reset Network Error",
//         "Validate Password Reset network error.",
//         {
//           email: emailAddress,
//           hash: validationCode,
//           response: err,
//         }
//       );
//       return false;
//     });
// }

/**
 *
 * @param userId
 * @returns boolean - no data gets returned, so just checking for true/false values to determine outcome
 */
async function requestAccountDeletion(userId: string): Promise<boolean> {
  const data = {
    industry: parseInt(industry),
  };
  return post(`/users/${userId}/request_delete`, data)
    .then((response) => {
      return response.status === 204;
    })
    .catch(() => false);
}

async function deleteUser(userEmail: string, hash: string): Promise<boolean> {
  return post(`/users/confirm_delete?email=${userEmail}&h=${hash}`)
    .then((response) => {
      return response.status === 204;
    })
    .catch(() => false);
}

function formatTimestamp(timestamp: number): string {
  const d = new Date(timestamp * 1000);
  const options: DateTimeFormatOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return (
    d.toLocaleDateString(
      store.getters["settings/languageSetting"] + "-US",
      options
    ) +
    " " +
    d.toLocaleTimeString("en-US")
  );
}

async function startTrialHandler(userId: string): Promise<string> {
  const industryId = parseInt(industry);
  return (
    post(`/users/${userId}/trial/industries/${industryId}`)
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      .then(async (response: any | GenericApiError) => {
        if (response.status === 204) {
          // Syncing user data so user has updated features (realtalk, local etc)
          await userService.syncUserData(userId, { auth: true });
          return "";
        } else {
          if ("error" in response) {
            helpers.customSentryError(
              "Free Trial Failure",
              `failed to initialize free trial for: ${userId}`,
              { error: response.error }
            );
            return response.error as string;
          }
        }
      })
      .catch((e) => {
        helpers.customSentryError(
          "Free Trial Failure",
          `failed to initialize free trial for: ${userId}`,
          { error: e }
        );
        return e.response.data.message;
      })
  );
}
