import TrackingService from "@/services/trackingservice/tracking.service";
import { Route } from "@sentry/vue/types/router";
import { RouteConfig } from "vue-router";
import store from "@/state/store";

const routes: RouteConfig[] = [
  {
    path: "/shareable-content/:contentType(blog)/:slug",
    name: "Shareable Content Detail Blog",
    props: true,
    meta: { authRequired: true },
    component: () =>
      import("@/router/views/personalizedContent/shareable-content-detail.vue"),
    beforeEnter: (to: Route, from: Route, next): void => {
      if (!to.query.fiab) {
        const abValue = TrackingService.AorB(store.getters["auth/authEmail"]);

        next({
          path: to.path,
          query: { ...to.query, fiab: `${abValue}` },
        });
      } else {
        next();
      }
    },
  },

  {
    path: "/shareable-content/:contentType(graphic)/:slug",
    name: "Shareable Content Detail Graphic",
    props: true,
    meta: { authRequired: true, featureRequired: "social-graphics" },
    component: () =>
      import("@/router/views/personalizedContent/shareable-content-detail.vue"),
  },
  {
    path: "/search",
    name: "results",
    meta: { authRequired: true },
    component: () =>
      import("@/router/views/personalizedContent/search-page.vue"),
  },
];

const redirects: RouteConfig[] = [
  { path: "/categories", redirect: "/shareable-content" },
  {
    path: "/blog",
    redirect: {
      path: "/shareable-content/",
      query: {
        types: "blog",
      },
    },
  },
  {
    path: "/pp",
    redirect: {
      path: "/shareable-content/",
      query: {
        types: "blog",
      },
    },
  },
  {
    path: "/:langSlug?/blog/:slug",
    redirect: "/shareable-content/blog/:slug",
  },
  {
    path: "/content/blog/:slug",
    redirect: "/shareable-content/blog/:slug",
  },
  {
    path: "/:langSlug?/20*/*/*/:slug",
    redirect: "/shareable-content/blog/:slug",
  },
  {
    path: "/socialgraphics",
    redirect: {
      path: "/shareable-content/",
      query: {
        types: "social-graphics",
      },
    },
  },
  {
    path: "/social-graphics",
    redirect: {
      path: "/shareable-content/",
      query: {
        types: "social-graphics",
      },
    },
  },
  {
    path: "/infographics",
    redirect: {
      path: "/shareable-content/",
      query: {
        types: "blog",
        categories: "infographics",
      },
    },
  },
  {
    path: "/content/social-graphics/:slug",
    redirect: "/shareable-content/graphic/:slug",
  },
];

/**
 * Routes that are specific to MyKCM / BB go here
 */
const industrySpecificRoutes: Record<string, RouteConfig[]> = {
  "1": [
    {
      path: "/shareable-content",
      name: "Shareable Content",
      meta: { authRequired: true },
      component: () =>
        import("@/router/views/personalizedContent/shareable-content.vue"),
      props: { types: "blog,videos,social-graphics" },
    },
    {
      path: "/shareable-content/:contentType(infographic)/:slug",
      name: "Shareable Content Detail Infographic",
      props: true,
      meta: { authRequired: true },
      component: () =>
        import(
          "@/router/views/personalizedContent/shareable-content-detail.vue"
        ),
    },
    {
      path: "/shareable-content/:contentType(video)/:slug",
      name: "Shareable Content Detail Video",
      props: true,
      meta: { authRequired: true, featureRequired: "video" },
      component: () =>
        import(
          "@/router/views/personalizedContent/shareable-content-detail.vue"
        ),
    },
    {
      path: "/videos",
      redirect: { name: "Shareable Content", query: { types: "videos" } },
    },
    {
      path: "/videos/:slug",
      redirect: { path: "/shareable-content/video/:slug" },
    },
    {
      path: "/:langSlug?/videos/:slug",
      redirect: { path: "/shareable-content/video/:slug" },
    },
    {
      path: "/content/videos/:slug",
      redirect: { path: "/shareable-content/video/:slug" },
    },
  ],
  "2": [
    {
      path: "/shareable-content",
      name: "Shareable Content",
      meta: { authRequired: true },
      component: () =>
        import("@/router/views/personalizedContent/shareable-content.vue"),
      props: { types: "blog,social-graphics" },
    },
  ],
};

const contentRoutes: Record<string, RouteConfig[]> = Object.keys(
  industrySpecificRoutes
).reduce((acc, industryId) => {
  acc[industryId] = [
    ...routes,
    ...industrySpecificRoutes[industryId],
    ...redirects,
  ];
  return acc;
}, {} as Record<string, RouteConfig[]>);

export default [...routes, ...redirects];
export { contentRoutes };
