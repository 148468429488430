import type { KcmContent, AuthUser } from "@/types";
import type { RecurlySubscriptionData } from "@/types/recurly.types";
import type { ApplePaySubscriptionData } from "@/types";
import store from "@/state/store";

interface FilterEvent {
  page: number;
  categories: string;
  types: string;
  query: string;
  published_since: string;
  language: string;
}

interface SearchEvent {
  query: string;
}

interface DownloadEvent {
  file_extension: string;
  file_name: string;
}

interface SocialMediaEvent {
  social_platform: string;
}

interface GenericEventDataEvent {
  event_target: string;
}

type EventData =
  | DownloadEvent
  | SearchEvent
  | FilterEvent
  | SocialMediaEvent
  | GenericEventDataEvent
  | undefined;

// what is sent to the tracking lambda
interface TrackingPayload {
  event: string;
  user: AuthUser;
  subscription: RecurlySubscriptionData | ApplePaySubscriptionData;
  content: KcmContent;
  eventData?: EventData;
}

/**
 *
 * this is basically a low hanging patch until we can drop
 * the CZ tracker. we don't want to change any event names
 * being sent to CZ, and the event names we need to send to
 * the lambda tracker are sometimes different, so this allows
 * us to send the new event names without modifying names
 * in any other files.
 */
const eventNameMap: { [key: string]: string } = {
  // Content Events
  viewedContent: "view",
  sharedContent: "share",
  scheduledContent: "schedule",
  watchedContent: "watch",
  downloadedContent: "download",
  save: "save",
  filteredContent: "filter",
  searched: "search",
  playedAudio: "playAudio",
  upload: "upload",
  selectedContent: "selectContent",
  create: "create",
  customize: "customize",
  copiedContent: "copy",
  // Account Events
  login: "login",
  connectedSocial: "connectSocialMedia",
  startTestDrive: "startTestDrive",
  // Subscription Events
  initiatePlanChange: "initiatePlanChange",
  startCancel: "startCancel",
  acceptCancelSave: "acceptCancelSave",
  acceptCancelDeflect: "acceptCancelDeflect",
  closeCancel: "closeCancel",
  declineCancelSave: "declineCancelSave",
};

function generateBasePayload(eventSlug: string, data: any): TrackingPayload {
  return {
    event: eventSlug,
    user: store.getters["auth/authUser"],
    subscription: store.getters["billing/billingSubscription"],
    content: data.content ?? {},
    eventData: {} as EventData,
  };
}

/**
 *
 * @param payload tracking payload
 * @param data the data coming from the trackEvent function which has a lot of different structures :(
 *
 * evaluates the payload event against the eventNameMap and prepares
 * any special event eventData with the needed format for the lambda tracker
 */
function prepareEventData(payload: TrackingPayload, data: any): void {
  switch (payload.event) {
    case eventNameMap.sharedContent:
      payload.eventData = ContentEventFormatters.prepareShareEvent(data);
      break;
    case eventNameMap.downloadedContent:
      payload.eventData = ContentEventFormatters.prepareDownloadEvent(data);
      break;
    case eventNameMap.filteredContent:
      payload.eventData = ContentEventFormatters.prepareFilterEvent(data);
      break;
    case eventNameMap.searched:
      payload.eventData = ContentEventFormatters.prepareSearchEvent(data);
      break;
    case eventNameMap.copiedContent:
      payload.eventData = ContentEventFormatters.prepareCopyEvent(data);
      break;
    case eventNameMap.upload:
      payload.eventData = ContentEventFormatters.prepareUploadEvent(data);
      break;
    case eventNameMap.customize:
      payload.eventData = ContentEventFormatters.prepareCustomizeEvent(data);
      break;
    case eventNameMap.save:
      payload.eventData = ContentEventFormatters.prepareSaveEvent(data);
      break;
    case eventNameMap.create:
      payload.eventData = ContentEventFormatters.prepareCreateEvent(data);
      break;
    case eventNameMap.connectedSocial:
      payload.eventData =
        AccountEventFormatters.prepareConnectSocialEvent(data);
      break;
    case eventNameMap.acceptCancelSave:
      payload.eventData =
        SubscriptionEventFormatters.prepareCancelDeflectEvent(data);
      break;
    case eventNameMap.acceptCancelDeflect:
      payload.eventData =
        SubscriptionEventFormatters.prepareCancelSaveEvent(data);
      break;
    default:
      delete payload.eventData;
      break;
  }
}

/**
 *
 * @param payload tracking payload
 * @param action the event action
 *
 * evaluates the event action against the event name map and modifies
 * the event string to the format needed for the lambda tracker
 */
function prepareEventSlug(payload: TrackingPayload, action: string): void {
  switch (payload.event) {
    case eventNameMap.watchedContent:
      payload.event = formatWatchedPercentage(action);
      break;
    default:
      break;
  }
}

/**
 *
 * @param watchedPercentage string ex. (50%)
 * @returns the event slug format required for lambda tracker: ex. watch50
 */
function formatWatchedPercentage(watchedPercentage: string): string {
  const regexp = /\(([^()]*)\)/g;
  // ex. [(50%)]
  const matches = watchedPercentage.match(regexp);
  if (!matches) return "";
  // extract percent value in between paranthesis
  const percent = matches.map((match) => match.slice(1, -1))[0];
  // ex. watch95
  return "watch" + percent.split("%")[0];
}

/**
 *
 * Group event formatters for content related events
 * that require unique metadata for the eventData property
 */
const ContentEventFormatters = {
  prepareShareEvent(data: any): SocialMediaEvent {
    return {
      social_platform: data.method,
    };
  },
  prepareDownloadEvent(data: any): DownloadEvent {
    const splitFileName = data.fileName.split(".");

    return {
      file_extension: splitFileName[1],
      file_name: splitFileName[0],
    };
  },

  prepareSearchEvent(data: any): SearchEvent {
    return {
      query: data,
    };
  },

  prepareCopyEvent(data: any): GenericEventDataEvent {
    return {
      event_target: data.eventTarget,
    };
  },

  prepareUploadEvent(data: any): GenericEventDataEvent {
    return {
      event_target: data,
    };
  },

  prepareFilterEvent(data: any): FilterEvent {
    return {
      page: data.page,
      categories: data.categories,
      types: data.types,
      query: data.query ?? "",
      published_since: data.published_since,
      language: data.language ?? "",
    };
  },

  prepareCustomizeEvent(data: any): GenericEventDataEvent {
    return {
      event_target: data.eventTarget,
    };
  },

  prepareSaveEvent(data: any): GenericEventDataEvent {
    return {
      event_target: data,
    };
  },

  prepareCreateEvent(data: any): GenericEventDataEvent {
    return {
      event_target: data.eventTarget,
    };
  },
};

/**
 *
 * Group event formatters for account related events
 * that require unique metadata for the eventData property
 */
const AccountEventFormatters = {
  prepareConnectSocialEvent(data: any): SocialMediaEvent {
    return {
      social_platform: data.media,
    };
  },
};

/**
 *
 * Group event formatters for subscription related events
 * that require unique metadata for the eventData property
 */
const SubscriptionEventFormatters = {
  prepareCancelSaveEvent(data: any): GenericEventDataEvent {
    return {
      event_target: data,
    };
  },

  prepareCancelDeflectEvent(data: any): GenericEventDataEvent {
    return {
      event_target: data,
    };
  },
};

/**
 *
 * @param label the name of the event
 * @param data the <any> data passed in from TrackingService.trackEvent
 *
 * massages the data and the event names to the correct format before
 * sending the data off to the remote tracker
 */
export async function trackEvent(label: string, data: any): Promise<void> {
  const event = eventNameMap[label];

  if (!event) return;

  const payload = generateBasePayload(event, data);

  prepareEventData(payload, data);

  if (data.action) {
    prepareEventSlug(payload, data.action);
  }

  sendEvent(payload);
}

function sendEvent(payload: TrackingPayload, verbosity = false): void {
  const action = "?action=track";
  const verbose = "&verbose=true";

  let baseUrl = process.env.VUE_APP_TRACKER_URL;

  if (!baseUrl) return;

  baseUrl += action;

  if (verbosity) {
    baseUrl += verbose;
  }

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  };

  // just ignoring the response for now
  fetch(baseUrl, options);
}
