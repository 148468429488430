import {
  ApplePaySubscriptionData,
  BillingGenericError,
  BillingGenericNoData,
  KcmBilling,
  KcmCouponData,
  KCMSubscriptionCreateChangeData,
  KCMSubscriptionCreateData,
  KCMSubscriptionUpdateData,
  PlanData,
} from "@/types/index";
import store from "@/state/store";
import { applePayService } from "./applepay.service";
import { recurlyService } from "./recurly.service";
import { guestService } from "./guest.service";
import {
  RecurlySubscriptionData,
  RecurlyAccountData,
  RecurlyAccountBalanceData,
  RecurlyPurchaseData,
} from "@/types/recurly.types";
import {
  thirdPartyService,
  Emails,
} from "@/services/internal-api/thirdparty.service";
import { KCMBillingUtils } from "@/composables/billingUtils";
import { userService } from "../internal-api/auth.service";

export { paymentTypeCss } from "./utils/paymentLogos";

const industry = (process.env.VUE_APP_INDUSTRY_ID as string) ?? "1";
const billingUtils = new KCMBillingUtils();

const guestServiceWrapper = {
  check: (): boolean => true,
  service: guestService,
  id: "guest",
};

const services = {
  recurly: {
    check: isRecurly,
    service: recurlyService,
    id: "recurly",
  },
  apple: {
    check: isApplePay,
    service: applePayService,
    id: "apple",
  },
  guestServiceWrapper,
};

const billingFrequencyOptions = [
  { text: "Monthly", value: "monthly" },
  { text: "Annual", value: "annual" },
  { text: "Semi-Annual", value: "semiannual" },
];

type serviceKeys = keyof typeof services;

function myService(service?: KcmBilling): KcmBilling {
  return service
    ? service
    : (store.getters["billing/billingStoreService"] as KcmBilling);
}

export const billingService = {
  serviceId,
  getSubscriptionStatus,
  getSubscriptionData,
  getAccountData,
  getPlans,
  getCouponCodes,
  getPaymentService,
  createSubscription,
  updateSubscription,
  createChangeSubscription,
  pauseSubscription,
  cancelPendingPauseSubscription,
  resumePausedSubscription,
  cancelPendingChanges,
  reactivateSubscription,
  updateAccount,
  validateCoupon,
  applyCoupon,
  getAccountBalance,
  redirects,
  createPurchase,
  getProratedCharge,
  getInvoices,
  downloadInvoice,
  billingFrequencyOptions,
};

function serviceId(): string {
  return myService().serviceId();
}

async function getSubscriptionStatus(user_id: string): Promise<string> {
  return await myService().status(user_id);
}

async function getSubscriptionData(
  checkCache: boolean,
  userId?: string
): Promise<
  RecurlySubscriptionData | ApplePaySubscriptionData | BillingGenericNoData
> {
  const service = myService();
  if (!service.subscription_data) {
    return { error: "No get subscription function for this service" };
  }
  if (
    checkCache &&
    store.getters["billing/billingSubscription"] !== undefined &&
    Object.keys(store.getters["billing/billingSubscription"]).length > 0
  ) {
    return store.getters["billing/getSubscriptionData"];
  }

  const finalUserId = userId ? userId : store.getters["auth/authUserId"];

  const subscriptionData = await service.subscription_data(finalUserId);

  await store.dispatch("billing/setSubscriptionData", subscriptionData);
  return subscriptionData;
}

async function getAccountData(
  checkCache: boolean,
  userId?: string
): Promise<RecurlyAccountData | BillingGenericNoData> {
  const service = myService();
  if (!service.account_data) {
    return { error: "No get account function for this service" };
  }

  if (
    checkCache &&
    store.getters["billing/billingAccount"] !== undefined &&
    Object.keys(store.getters["billing/billingAccount"]).length > 0
  ) {
    return store.getters["billing/billingAccount"];
  }

  const finalUserId = userId ? userId : store.getters["auth/authUserId"];

  const accountData = await service.account_data(finalUserId);
  await store.dispatch("billing/setAccountData", accountData);
  return accountData;
}
const noEnterprisePlanFilter = (plan: PlanData): boolean => {
  return !plan.code.includes("enterprise");
};

async function getPlans(
  checkCache = true,
  planFilter = noEnterprisePlanFilter
): Promise<PlanData[]> {
  const service = myService();
  if (!service.plans) {
    return [];
  }

  if (
    checkCache &&
    Object.keys(store.getters["billing/billingPlans"]).length !== 0
  ) {
    return store.getters["billing/billingPlans"];
  }
  let planData = (await service.plans()) as PlanData[];

  if (planFilter) {
    planData = planData.filter(planFilter);
  }

  planData.map(function (plan) {
    plan.slug = plan.code;
    plan.price = plan.currencies[0].unit_amount;
    plan.legacy = 0;
    plan.video = planVideo(plan.code);

    // apply legacy pricing to plans
    if (store.getters["billing/billingAccount"]?.custom_fields?.length > 0) {
      for (const customField of store.getters["billing/billingAccount"]
        .custom_fields) {
        if (customField.name.includes("legacy_pricing_")) {
          const tempPlanSlug =
            customField.name.replace("legacy_pricing_", "") + "-monthly";
          if (
            plan.slug === tempPlanSlug &&
            plan.price !== JSON.parse(customField.value).price
          ) {
            plan.legacy = JSON.parse(customField.value).price;
          }
        }
      }
    }
    return plan;
  });

  planData.sort(comparePlansPrice);

  await store.dispatch("billing/setPlansData", planData);
  return planData;
}

function planVideo(plan: string): string {
  if (plan.includes("basic")) {
    return "https://player.vimeo.com/video/704293903?h=9622c413c5&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479";
  } else if (plan.includes("pro")) {
    return "https://player.vimeo.com/video/637228108?h=09175c6b11&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479";
  } else if (plan.includes("premium")) {
    return "https://player.vimeo.com/video/703755893?h=ff80630f40&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479";
  }
  return "";
}

async function getCouponCodes(): Promise<KcmCouponData[]> {
  if (store.getters["auth/authIsCrew"]) {
    return [];
  }
  const service = myService();
  if (!service.coupon_codes || !service.parse_coupons) {
    return [];
  }
  const svcCouponData = await service.coupon_codes();
  const kcmCouponData = service.parse_coupons(svcCouponData);

  return kcmCouponData;
}

async function createSubscription(
  id: string,
  payload: KCMSubscriptionCreateData
): Promise<RecurlySubscriptionData | BillingGenericError | null> {
  const service = myService();
  if (!service.create_subscription) {
    return null;
  }
  const response = await service.create_subscription(id, payload);

  if (response && !("error" in response)) {
    await userService.syncUserData(id, {
      auth: true,
      profile: true,
      billing: true,
      crm: true,
    });
    await getSubscriptionData(false, id);
    await getAccountData(false, id);
  }

  return response;
}

async function updateSubscription(
  id: string,
  payload: KCMSubscriptionUpdateData
): Promise<RecurlySubscriptionData | BillingGenericError | null> {
  const service = myService();
  if (!service.update_subscription) {
    return null;
  }
  const response = await service.update_subscription(id, payload);

  if (response && !("error" in response)) {
    await getSubscriptionData(false, id);
    await getAccountData(false, id);
    await userService.syncUserData(id, {
      auth: true,
      profile: true,
      billing: true,
      crm: true,
    });
  }

  return response;
}

async function createChangeSubscription(
  id: string,
  subscriptionId: string,
  payload: KCMSubscriptionCreateChangeData
): Promise<RecurlySubscriptionData | BillingGenericError> {
  const service = myService();
  if (!service.create_change_subscription) {
    return {
      error:
        "Create Change Subscription does not exists for this billing service",
    };
  }
  const response = await service.create_change_subscription(
    subscriptionId,
    payload
  );

  if (response && !("error" in response)) {
    await getSubscriptionData(false, id);
    await getAccountData(false, id);
    await userService.getAccount(id);
  }

  return response;
}

async function pauseSubscription(
  id: string,
  subscriptionId: string,
  length: number,
  reason: string
): Promise<RecurlySubscriptionData | BillingGenericError | null> {
  const service = myService();
  if (!service.pause_subscription) {
    return null;
  }
  const response = await service.pause_subscription(
    subscriptionId,
    length,
    reason
  );

  if (response && !("error" in response)) {
    await getSubscriptionData(false, id);
    await getAccountData(false, id);
  }

  return response;
}

async function cancelPendingPauseSubscription(
  id: string,
  subscriptionId: string
): Promise<RecurlySubscriptionData | BillingGenericError | null> {
  const service = myService();
  if (!service.pause_subscription) {
    return null;
  }
  const response = await service.pause_subscription(subscriptionId, 0, "");

  if (response && !("error" in response)) {
    thirdPartyService.sendTransactionalEmail(
      Emails[industry as keyof typeof Emails].CancelRequestToPause,
      store.getters["auth/authEmail"],
      {
        unpauseDate: billingUtils.formatDateStr(response.current_term_ends_at),
      }
    );
    await userService.syncUserData(id, {
      auth: true,
      profile: true,
      billing: true,
      crm: true,
    });
    await getSubscriptionData(false, id);
    await getAccountData(false, id);
  }

  return response;
}

async function resumePausedSubscription(
  id: string,
  subscriptionId: string
): Promise<RecurlySubscriptionData | BillingGenericError | null> {
  const service = myService();
  if (!service.resume_subscription) {
    return null;
  }
  const response = await service.resume_subscription(subscriptionId);

  if (response && !("error" in response)) {
    await userService.syncUserData(id, {
      auth: true,
      profile: true,
      billing: true,
      crm: true,
    });
    await getSubscriptionData(false, id);
    await getAccountData(false, id);
  }

  return response;
}

async function cancelPendingChanges(
  id: string,
  subscriptionId: string
): Promise<BillingGenericError | null> {
  const service = myService();
  if (!service.cancel_pending_changes) {
    return null;
  }
  const response = await service.cancel_pending_changes(subscriptionId);

  if (!response) {
    await userService.syncUserData(id, {
      auth: true,
      profile: true,
      billing: true,
      crm: true,
    });
    await getSubscriptionData(false, id);
    await getAccountData(false, id);
  }

  return response;
}

async function reactivateSubscription(
  id: string,
  subscriptionId: string
): Promise<RecurlySubscriptionData | BillingGenericError | null> {
  const service = myService();
  if (!service.reactivate_subscription) {
    return null;
  }

  const response = await service.reactivate_subscription(subscriptionId);

  if (response && !("error" in response)) {
    await getSubscriptionData(false, id);
    await getAccountData(false, id);
  } else {
    return Promise.reject({ error: response?.error });
  }

  return response;
}

function updateAccount(id: string, payload: string): void {
  const service = myService();
  if (!service.update_account) {
    return;
  }
  service.update_account(id, payload);
  store.dispatch("billing/setAccountData", {
    account: null,
  });
}

async function validateCoupon(
  code: string
): Promise<KcmCouponData | BillingGenericNoData> {
  const service = myService();
  if (!service.validate_coupon || !service.parse_coupons) {
    return { error: "There is no validation for this service" };
  }
  const svcCouponData = await service.validate_coupon(code);

  if ("error" in svcCouponData) {
    return svcCouponData;
  }

  const kcmCouponData = service.parse_coupons([svcCouponData]);
  return kcmCouponData[0];
}

function applyCoupon(id: string, payload: string): void {
  const service = myService();
  if (!service.apply_coupon) {
    return;
  }
  service.apply_coupon(id, payload);
  store.dispatch("billing/setSubscriptionData", {
    subscription: null,
  });
}

async function getAccountBalance(
  userId?: string
): Promise<RecurlyAccountBalanceData | null> {
  const service = myService();
  if (!service.account_balance) {
    return null;
  }
  const finalUserId = userId ? userId : store.getters["auth/authUserId"];

  const balanceData = await service.account_balance(finalUserId);
  return balanceData;
}

async function getPaymentService(): Promise<serviceKeys | null> {
  let returnService = null;
  for (let i = 0; i < Object.values(services).length; i++) {
    const resp = await Object.values(services)[i].check();
    if (resp) {
      returnService = Object.values(services)[i];
      break;
    }
  }
  if (returnService === null) {
    returnService = guestServiceWrapper;
  }
  await store.dispatch("billing/setBillingSource", returnService.id);
  return returnService.id as serviceKeys;
}

function isApplePay(): boolean {
  return applePayService.status() === "active";
}

async function isRecurly(): Promise<boolean> {
  const userId = store.getters["auth/authUserId"];
  const acc = await recurlyService.account_data(userId);
  if (acc?.has_live_subscription) {
    await store.dispatch("billing/setAccountData", acc);
    await getSubscriptionData(false);
    return true;
  }
  return false;
}

function comparePlansPrice(a: PlanData, b: PlanData): number {
  if (a.price < b.price) {
    return -1;
  }
  if (a.price > b.price) {
    return 1;
  }
  return 0;
}

function redirects(): { [x: string]: string } {
  return myService()?.redirects() ?? {};
}

async function createPurchase(
  payload: RecurlyPurchaseData
): Promise<void | BillingGenericError | null> {
  const service = myService();
  if (!service.create_purchase) {
    return null;
  }
  const resp = await service.create_purchase(payload);
  if (!resp?.error) {
    await getAccountData(false);
    await getSubscriptionData(false);
  }

  return resp;
}

async function getProratedCharge(
  user_id: string,
  plan: string,
  coupon: string
): Promise<number> {
  const service = myService();
  if (!service.get_prorated_charge) {
    return 0;
  }
  const charge = await service.get_prorated_charge(user_id, plan, coupon);

  return charge;
}

async function getInvoices(
  user_id: string,
  params?: {
    limit?: number;
    ids?: number[];
    order?: string;
    sort?: string;
    beginTime?: string;
    endTime?: string;
    type?: string;
  }
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
): Promise<Record<string, any>[]> {
  return (
    (await myService().get_invoices?.(user_id, params ?? { limit: 10 })) ?? []
  );
}

function downloadInvoice(
  user_id: string,
  invoice_number: number
): Promise<Blob> {
  return (
    myService().download_invoice?.(user_id, invoice_number) ??
    Promise.reject("failed to download invoice")
  );
}
